import colors from "theme/colors"

export const geely = {
  primaryColor: colors.darkBlue,
  secondaryColor: colors.geelyPurple,
  menuColor: [colors.darkBlue, colors.darkBlue],
  background: [
    {
      color: colors.geelyLightBlue,
      top: "-45%",
      scale: 0.6,
    },
    {
      color: colors.geelyLightBlue,
      top: "-10%",
      right: "50%",
      left: "auto",
      scale: 1,
    },
  ],
  arrowColor: [colors.darkBlue, colors.darkBlue],
  buttonColors: {
    color: [colors.geelyPurple, colors.geelyPurple],
    textColor: [colors.white, colors.white],
  },
  bouncingIcons: [
    {
      image: "./geely/geely-left.svg",
      deg: 80,
      left: ["20px", "55%"],
      right: ["", ""],
      bottom: "15%",
      width: ["43px", "131.5px"],
      height: ["45px", "135.5px"],
    },
    {
      image: "./geely/geely-right.svg",
      deg: -45,
      right: ["30px", "60px"],
      left: ["", ""],
      top: "50%",
      width: ["72", "50px"],
      height: ["72", "50px"],
    },
    {
      image: "./geely/geely-right.svg",
      deg: -60,
      right: ["0", "0"],
      left: ["", ""],
      bottom: "20%",
      width: ["0px", "144.3px"],
      height: ["0px", "183.1px"],
    },
  ],
  product: {
    name: "Geely",
    description:
      "Advanced ecom platform to find, reserve and purchase your new Geely  in an online quick and secured process.",
    logo: "/geely/geely-logo",
    link: "https://geely.co.il",
    image: "/geely/geely-phone",
    imageDeg: 30,
    services: ["UI/UX", "Web Development", "Ecommerce"],
    servicesColor: {
      color: colors.geelyVeryLightBlue,
      textColor: colors.black,
    },
    platform: "web",
  },
  hash: "geely",
  progressBarText: "Projects",
  progressBarColors: {
    activeColor: colors.darkBlue,
    inactiveColor: colors.grayWhite,
  },
}
