import { Drawer, List, ListItem } from "@material-ui/core"
import { GlobalContext } from "common/GlobalContext"
import { useContext, useEffect, useState } from "react"
import { useSwipeable } from "react-swipeable"
import styled from "styled-components"
import {
  finalBounceEase,
  menuTransitionTime,
  transition,
  transitionTime,
} from "theme/shared"

const Styled = {
  Drawer: styled(Drawer)`
    z-index: 10 !important;
    .MuiBackdrop-root {
      background-color: transparent;
    }
    .MuiDrawer-paperAnchorRight {
      width: 100%;
      box-shadow: none;
      background-color: transparent;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      transition: none !important;
      transform: none !important;
      overflow: hidden;
    }
  `,
  MenuBackground: styled.div<{ active: boolean }>`
    position: absolute;
    width: 200vh;
    height: 200vh;
    border-radius: 50%;
    right: 0;
    top: 0;
    background-color: ${(p) => p.theme.colors.darkBlue};
    ${transition};
    opacity: 0;
    transform: translate3d(50%, -50%, 0) scale(0.1);
    ${(p) =>
      p.active &&
      `transform: translate3d(37%,-40%,0) scale(1);
        opacity: 1;
    `}
    ${(p) => p.theme.breakpoints.up("md")} {
      ${(p) =>
        p.active &&
        `transform: translate3d(37%,-40%,0) scale(2);
      `}
    }
  `,
  ListItem: styled(ListItem).attrs({
    autoFocus: true,
    button: true,
    component: "a",
  })<{ href: string }>`
    text-transform: uppercase;
    font-size: 30px;
    text-align: center;
    padding: 10px 0;
    color: ${(p) => p.theme.colors.white45};
    font-weight: 400;
    justify-content: center;
    visibility: hidden;
    opacity: 0;

    ${transition}
    /* transition: all ${transitionTime}ms ${finalBounceEase},
      opacity ${menuTransitionTime}ms ease-in-out; */

    background-color: transparent !important;
    b {
      color: ${(p) => p.theme.colors.white};
      font-weight: bold;
    }

    ${(p) =>
      p.selected &&
      `
        transform: translate3d(0, 0, 0) !important;
        visibility: visible;
        opacity: 1;
    `}
    &:nth-child(1) {
      transform: translate3d(0, 600%, 0);
    }
    &:nth-child(2) {
      transform: translate3d(0, 500%, 0);
    }
    &:nth-child(3) {
      transform: translate3d(0, 400%, 0);
    }
    &:nth-child(4) {
      transform: translate3d(0, 200%, 0);
    }
    &:nth-child(5) {
      transform: translate3d(0, 100%, 0);
    }

    &.contact {
      margin-top: 30px;
      transform: translate3d(0, 100%, 0);
      transition: all ${menuTransitionTime}ms
        ${transitionTime - menuTransitionTime}ms ${finalBounceEase};

      ${(p) =>
        !p.selected &&
        `transition: all ${menuTransitionTime}ms ${finalBounceEase};`}
    }
  `,
  Dot: styled.span`
    color: ${(p) => p.theme.colors.hotPink};
  `,
  Circle: styled.span`
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background-color: ${(p) => p.theme.colors.hotPink};
    margin-left: 0.1em;
    margin-bottom: -1px;
  `,
}

const Menu = () => {
  const { openMenu, setOpenMenu } = useContext(GlobalContext)
  const [showBackground, setShowBackground] = useState(false)

  const handlers = useSwipeable({
    onSwipedUp: () => handleDrawerClose(),
  })

  useEffect(() => {
    let openTimeout
    if (openMenu) {
      openTimeout = setTimeout(() => {
        setShowBackground(true)
      }, 1)
    } else {
      setShowBackground(false)
    }

    return () => {
      clearTimeout(openTimeout)
    }
  }, [openMenu])

  const handleDrawerClose = () => setOpenMenu(false)

  return (
    <Styled.Drawer
      anchor="right"
      open={openMenu}
      onClose={handleDrawerClose}
      transitionDuration={transitionTime}
    >
      <Styled.MenuBackground {...handlers} active={showBackground} />
      <List disablePadding>
        <Styled.ListItem
          href="#home"
          selected={showBackground}
          onClick={handleDrawerClose}
        >
          <b>
            home<Styled.Circle></Styled.Circle>
          </b>
        </Styled.ListItem>

        <Styled.ListItem
          href="#services"
          selected={showBackground}
          onClick={handleDrawerClose}
        >
          services
        </Styled.ListItem>

        <Styled.ListItem
          href="#clients"
          selected={showBackground}
          onClick={handleDrawerClose}
        >
          Clients
        </Styled.ListItem>

        <Styled.ListItem
          href="#our-work"
          selected={showBackground}
          onClick={handleDrawerClose}
        >
          Our Work
        </Styled.ListItem>
        <Styled.ListItem
          href="#team"
          selected={showBackground}
          onClick={handleDrawerClose}
        >
          Leadership Team
        </Styled.ListItem>

        {/* <NextLink passHref href="">
          <Styled.ListItem
            selected={showBackground}
            onClick={handleDrawerClose}
          >
            Jobs
          </Styled.ListItem>
        </NextLink> */}
      </List>

      <Styled.ListItem
        href="#contact"
        selected={showBackground}
        className="contact"
        onClick={handleDrawerClose}
      >
        <b>
          <Styled.Dot>Talk to us</Styled.Dot>
        </b>
      </Styled.ListItem>
    </Styled.Drawer>
  )
}

export default Menu
