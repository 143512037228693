import { Section } from "Components/Sections/sections"
import { createContext, Dispatch, SetStateAction } from "react"

export type GlobalContextType = {
  activeSectionIndex: number
  setActiveSectionIndex: Dispatch<SetStateAction<number>>
  activeSection: Section
  openMenu: boolean
  setOpenMenu: Dispatch<SetStateAction<boolean>>
  active: number
  prev: number | null
  setPrev: Dispatch<SetStateAction<number>>
  next: number | null
}

export const GlobalContext = createContext<GlobalContextType>(null)
