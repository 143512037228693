import { sections } from "Components/Sections/sections"
import { FC, useEffect, useState } from "react"
import { transitionTime } from "theme/shared"
import { GlobalContext } from "./GlobalContext"

const GlobalContextProvider: FC = ({ children }) => {
  const [activeSectionIndex, setActiveSectionIndex] = useState(0)
  const [openMenu, setOpenMenu] = useState(false)
  const [active, setActive] = useState<number>(0)
  const [prev, setPrev] = useState<number | null>(null)
  const [next, setNext] = useState<number | null>(null)

  useEffect(() => {
    setNext(activeSectionIndex)
    const timeout = setTimeout(() => {
      setActive(activeSectionIndex)
    }, 1)
    return () => {
      clearTimeout(timeout)
    }
  }, [activeSectionIndex])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPrev(null)
      setNext(null)
    }, transitionTime)
    return () => {
      clearTimeout(timeout)
    }
  }, [prev])

  return (
    <GlobalContext.Provider
      value={{
        activeSectionIndex,
        setActiveSectionIndex,
        activeSection: sections[activeSectionIndex],
        openMenu,
        setOpenMenu,
        active,
        prev,
        setPrev,
        next,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalContextProvider
