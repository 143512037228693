import colors from "theme/colors"

export const toyota = {
  primaryColor: colors.darkBlue,
  secondaryColor: colors.brightRed,
  menuColor: [colors.brightRed, colors.white],
  background: [
    {
      color: colors.toyotaDarkGray,
      top: "50%",
      scale: 0.7,
    },
    {
      color: colors.toyotaDarkGray,
      top: "-10%",
      left: "52%",
      scale: 1,
    },
  ],
  arrowColor: [colors.white, colors.darkBlue],
  buttonColors: {
    color: [colors.toyotaRed, colors.toyotaRed],
    textColor: [colors.white, colors.white],
  },
  bouncingIcons: [
    {
      image: "./toyota/toyota-left.svg",
      deg: 45,
      left: ["25px", "50%"],
      right: ["", ""],
      bottom: "40%",
      width: ["70px", "134.8px"],
      height: ["70px", "132.4px"],
    },
    {
      image: "./toyota/toyota-left-small.svg",
      deg: -45,
      left: ["75px", ""],
      right: ["", "25px"],
      bottom: "25%",
      width: ["28px", "40px"],
      height: ["58px", "40px"],
    },
    {
      image: "./toyota/toyota-right.svg",
      deg: 135,
      right: ["20px", ""],
      left: [null, null],
      bottom: "20%",
      width: ["", "0px"],
      height: ["", "0px"],
    },
    {
      image: "./toyota/toyota-right.svg",
      deg: 135,
      right: ["", "18px"],
      left: [null, null],
      top: "30%",
      width: ["", "90px"],
      height: ["", "90px"],
    },
  ],
  product: {
    name: "Toyota",
    description:
      "My toyota app offers useful information and a variety of tools to manage the maintenance of your Toyota.",
    logo: "/toyota/toyota-logo",
    link: "https://mytoyotail.page.link/download",
    image: "/toyota/toyota-phone",
    imageDeg: 25,
    services: ["UI/UX", "App Development", "CMS"],
    servicesColor: {
      color: colors.white,
      textColor: colors.toyotaDarkGray,
    },
    platform: "app",
  },
  hash: "toyota",
  progressBarText: "Projects",
  progressBarColors: {
    activeColor: colors.darkBlue,
    inactiveColor: colors.grayWhite,
  },
}
