import colors from "theme/colors"

export const strauss = {
  primaryColor: colors.darkBlue,
  secondaryColor: colors.white,
  menuColor: [colors.white, colors.brightSkyBlue],
  background: [
    {
      color: colors.brightSkyBlue,
      top: "-50%",
      scale: 0.6,
    },
    {
      color: colors.brightSkyBlue,
      top: "-10%",
      right: "50%",
      left: "auto",
      scale: 1,
    },
  ],
  arrowColor: [colors.brightSkyBlue, colors.darkBlue],
  buttonColors: {
    color: [colors.brightSkyBlue, colors.white],
    textColor: [colors.darkBlue, colors.brightSkyBlue],
  },
  bouncingIcons: [
    {
      image: "./blue_circle.svg",
      deg: 45,
      left: ["15px", "45%"],
      right: ["", ""],
      bottom: "65%",
      width: ["75px", "210.2px"],
      height: ["75px", "210.2px"],
    },
    {
      image: "./blue_circle.svg",
      deg: 135,
      left: ["63px", "52%"],
      right: ["", ""],
      bottom: "30%",
      width: ["29px", "71.7px"],
      height: ["29px", "71.7px"],
    },
    {
      image: "./blue_circle.svg",
      deg: -45,
      right: ["22px", "34px"],
      left: ["", ""],
      bottom: "28%",
      width: ["49px", "140.5px"],
      height: ["49px", "140.5px"],
    },
  ],
  product: {
    name: "Strauss+",
    description:
      "Loyalty platform that enables users to redeem points for a variety of gifts & coupons by scanning their supermarket receipts",
    logo: "/strauss/strauss_logo",
    link: "https://cc.strauss-group.com/content/html/deeplink/redirector.html",
    image: "/strauss/Strauss_iphone_blue_3D",
    imageDeg: 45,
    services: ["AI", "App Development", "OCR"],
    servicesColor: {
      color: colors.white,
      textColor: colors.darkBlue,
    },
    platform: "app",
  },
  hash: "strauss",
  progressBarText: "Projects",
  progressBarColors: {
    activeColor: colors.darkBlue,
    inactiveColor: colors.grayWhite,
  },
}
