import colors from "theme/colors"

export const cellcom = {
  primaryColor: colors.darkRoyalBlue,
  secondaryColor: colors.cerise,
  menuColor: [colors.cerise, colors.white],
  background: [
    {
      color: colors.darkishPurple,
      top: "50%",
      scale: 0.6,
    },
    {
      color: colors.darkishPurple,
      top: "-10%",
      left: "52%",
      scale: 1,
    },
  ],
  arrowColor: [colors.white, colors.darkBlue],
  buttonColors: {
    color: [colors.cerise, colors.cerise],
    textColor: [colors.white, colors.white],
  },
  bouncingIcons: [
    {
      image: "./cellcom/cellcom-right.svg",
      deg: 45,
      left: ["25px", "50%"],
      right: ["", ""],
      bottom: "33%",
      width: ["70px", "134.8px"],
      height: ["70px", "132.4px"],
    },
    {
      image: "./cellcom/cellcom-right.svg",
      deg: -45,
      left: ["75px", "57%"],
      right: ["", ""],
      bottom: "25%",
      width: ["28px", "53.8px"],
      height: ["58px", "52.8px"],
    },
    {
      image: "./cellcom/cellcom-right.svg",
      deg: 10,
      right: ["-30px", "-10px"],
      left: ["", ""],
      bottom: "32%",
      width: ["28px", "146.3px"],
      height: ["58px", "185.2px"],
    },
  ],
  product: {
    name: "Cellcom",
    description:
      "Redesign and development of the Cellcom website enabling users to join and perform a variety of self-service actions online",
    logo: "/cellcom/logo",
    link: "https://cellcom.co.il",
    image: "/cellcom/Cellcum_iphone_clay_3D",
    imageDeg: 15,
    services: ["UI/UX", "Web Development", "Design Thinking"],
    servicesColor: {
      color: colors.paleGrey,
      textColor: colors.darkishPurple,
    },
    platform: "web",
  },
  hash: "cellcom",
  progressBarText: "Projects",
  progressBarColors: {
    activeColor: colors.darkBlue,
    inactiveColor: colors.grayWhite,
  },
}
