import colors from "theme/colors"

export const mcdonalds = {
  primaryColor: colors.darkBlue,
  secondaryColor: colors.brightRed,
  menuColor: [colors.mcdonaldsYellow, colors.white],
  background: [
    {
      color: colors.mcdonaldsRed,
      top: "50%",
      scale: 0.6,
    },
    {
      color: colors.mcdonaldsRed,
      top: "-10%",
      left: "52%",
      scale: 1,
    },
  ],
  arrowColor: [colors.white, colors.darkBlue],
  buttonColors: {
    color: [colors.mcdonaldsYellow, colors.mcdonaldsYellow],
    textColor: [colors.black, colors.black],
  },
  bouncingIcons: [
    {
      image: "./mcdonalds/mcdonalds-left.svg",
      deg: 45,
      right: ["", ""],
      left: ["0px", "55%"],
      bottom: "10%",
      width: ["95px", "120px"],
      height: ["82.2px", "120px"],
    },
    {
      image: "./mcdonalds/mcdonalds-right-small.svg",
      deg: 45,
      right: ["15px", "30px"],
      left: ["", ""],
      bottom: "10%",
      width: ["40px", "50px"],
      height: ["40px", "50px"],
    },
    {
      image: "./mcdonalds/mcdonalds-right-small.svg",
      deg: 135,
      right: ["20px", "18px"],
      left: [null, null],
      bottom: "55%",
      width: ["", "100px"],
      height: ["", "100px"],
    },
  ],
  product: {
    name: "McDonalds",
    description:
      "Digital gift cards platform to customize and send McDonald's gift cards to your family and friends.",
    logo: "/mcdonalds/mcdonalds-logo",
    link: "https://gift.mcdonalds.co.il",
    image: "/mcdonalds/mcdonalds-phone",
    imageDeg: 0,
    services: ["UI/UX", "Web Development", "Ecommerce"],
    servicesColor: {
      color: colors.paleGrey,
      textColor: colors.black,
    },
    platform: "web",
  },
  hash: "mcdonalds",
  progressBarText: "Projects",
  progressBarColors: {
    activeColor: colors.darkBlue,
    inactiveColor: colors.grayWhite,
  },
}
