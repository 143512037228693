const colors = {
  darkBlue: "#000f60",
  white: "#fff",
  hotPink: "#ed008c",
  aquaMarine: "#42d9cd",
  white45: "rgba(255, 255, 255, 0.45)",
  brightRed: "#f40009",
  black: "#000000",
  veryLightPink: "#ffefef",
  brightSkyBlue: "#12afff",
  cherry: "#db0712",
  cobaltBlue: "#24419d",
  iceBlue: "#f5fcff",
  darkRoyalBlue: "#011272",
  dandelionYellow: "#fbe612",
  cherryTwo: "#cc0d34",
  pale: "#fffbce",
  mulberry: "#920a2d",
  grayWhite: "#f0f0f0",
  cerise: "#e20e87",
  darkishPurple: "#711769",
  paleGrey: "#f4f6f7",
  dodgerBlue: "#46b7fd",
  goldenYellow: "#fbca10",
  lightPeriwinkle: "#cbd7ff",
  lightMauve: "#ba99a2",
  BrandsCentralParkGreen: "#A7D86D",
  buymeYellow: "#FFC400",
  buymePink: "#DF0F65",
  buymeLightPink: "#FFF7FA",
  toyotaDarkGray: "#282830",
  toyotaRed: "#FF0022",
  geelyLightBlue: "#D8EBFF",
  geelyPurple: "#A238FF",
  geelyVeryLightBlue: "#F0F6FC",
  eldanYellow: "#FDC41F",
  eldanBlue: "#0070BF",
  eldanLightBlue: "#9BBBE3",
  eldanDarkBlue: "#002653",
  amotLightPink: "#FAF5F6",
  amotRed: "#C3001A",
  mcdonaldsYellow: "#FFBC0D",
  mcdonaldsRed: "#DB0007",
}

export default colors
