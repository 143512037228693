import colors from "theme/colors"

export const histadrut = {
  primaryColor: colors.darkRoyalBlue,
  secondaryColor: colors.dodgerBlue,
  menuColor: [colors.darkRoyalBlue, colors.black],
  background: [
    {
      color: colors.dodgerBlue,
      top: "50%",
      scale: 0.6,
    },
    {
      color: colors.dodgerBlue,
      top: "-10%",
      left: "52%",
      scale: 1,
    },
  ],
  arrowColor: [colors.white, colors.darkBlue],
  buttonColors: {
    color: [colors.white, colors.dodgerBlue],
    textColor: [colors.darkBlue, colors.darkBlue],
  },
  bouncingIcons: [
    {
      image: "./histadrut/histadrut-left.svg",
      deg: 80,
      left: ["0", "45%"],
      right: ["", ""],
      bottom: "15%",
      width: ["107.9px", "197.5px"],
      height: ["115.8px", "193.5px"],
    },
    {
      image: "./histadrut/histadrut-right.svg",
      deg: -45,
      right: ["42px", "60px"],
      left: ["", ""],
      top: "35%",
      width: ["", "91.6px"],
      height: ["", "92.4px"],
    },
    {
      image: "./histadrut/histadrut-right-bottom.svg",
      deg: -60,
      right: ["0", "0"],
      left: ["", ""],
      bottom: "20%",
      width: ["", "144.3px"],
      height: ["", "183.1px"],
    },
  ],
  product: {
    name: "histadrut",
    description:
      "An all in one hub by Israel's largest labor organization, offering a wide range of information and services to its members",
    logo: "/histadrut/histadrut-logo",
    link: "https://histadrut.page.link/app_install",
    image: "/histadrut/histadrut_iphone_blue_3D",
    imageDeg: 25,
    services: ["UI/UX", "App Development", "Design Thinking"],
    servicesColor: {
      color: colors.iceBlue,
      textColor: colors.darkBlue,
    },
    platform: "app",
  },
  hash: "histadrut",
  progressBarText: "Projects",
  progressBarColors: {
    activeColor: colors.darkBlue,
    inactiveColor: colors.grayWhite,
  },
}
