import { SvgIcon, SvgIconProps } from "@material-ui/core"
import { GlobalContext } from "common/GlobalContext"
import { useIsDesktop } from "common/MediaQueries"
import { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import colors from "theme/colors"
import { transition } from "theme/shared"

const Styled = {
  SvgIcon: styled(SvgIcon)`
    .transition {
      fill: currentColor;
      ${transition};
    }
  `,
}

const MenuClosed = (props: SvgIconProps) => {
  const [fill, setFill] = useState(colors.darkBlue)
  const { activeSection, openMenu } = useContext(GlobalContext)
  const isDesktop = useIsDesktop()

  useEffect(() => {
    // setFill(openMenu ? colors.white : activeSection.menuColor ?? activeSection.primaryColor)
    setFill(
      openMenu
        ? colors.white
        : isDesktop
        ? activeSection.menuColor[1]
        : activeSection.menuColor[0]
    )
  }, [openMenu, activeSection])

  return (
    <Styled.SvgIcon {...props} viewBox="0 0 30 28">
      <defs>
        <clipPath id="clip-path">
          <path fill="none" d="M0 0H30V28H0z" />
        </clipPath>
      </defs>
      <g id="Menu" clipPath="url(#clip-path)">
        <g transform="translate(-246 -151)">
          <circle
            id="Ellipse_6"
            cx="2.5"
            cy="2.5"
            r="2.5"
            className="transition"
            style={{ color: fill }}
            transform="translate(246 151)"
          />
        </g>
        {!openMenu && (
          <g transform="translate(-235 -151)">
            <circle
              id="Ellipse_6-2"
              cx="2.5"
              cy="2.5"
              r="2.5"
              className="transition"
              style={{ color: fill }}
              transform="translate(246 151)"
            />
          </g>
        )}
        <g transform="translate(-224 -151)">
          <circle
            id="Ellipse_6-3"
            cx="2.5"
            cy="2.5"
            r="2.5"
            className="transition"
            style={{ color: fill }}
            transform="translate(246 151)"
          />
        </g>
        {!openMenu && (
          <g transform="translate(-246 -141)">
            <circle
              id="Ellipse_6-4"
              cx="2.5"
              cy="2.5"
              r="2.5"
              className="transition"
              style={{ color: fill }}
              transform="translate(246 151)"
            />
          </g>
        )}
        <g transform="translate(-235 -141)">
          <circle
            id="Ellipse_6-5"
            cx="2.5"
            cy="2.5"
            r="2.5"
            className="transition"
            style={{ color: fill }}
            transform="translate(246 151)"
          />
        </g>
        {!openMenu && (
          <g transform="translate(-224 -141)">
            <circle
              id="Ellipse_6-6"
              cx="2.5"
              cy="2.5"
              r="2.5"
              className="transition"
              style={{ color: fill }}
              transform="translate(246 151)"
            />
          </g>
        )}
        <g transform="translate(-246 -131)">
          <circle
            id="Ellipse_6-7"
            cx="2.5"
            cy="2.5"
            r="2.5"
            className="transition"
            style={{ color: fill }}
            transform="translate(246 151)"
          />
        </g>
        {!openMenu && (
          <g transform="translate(-235 -131)">
            <circle
              id="Ellipse_6-8"
              cx="2.5"
              cy="2.5"
              r="2.5"
              className="transition"
              style={{ color: fill }}
              transform="translate(246 151)"
            />
          </g>
        )}
        <g transform="translate(-224 -131)">
          <circle
            id="Ellipse_6-9"
            cx="2.5"
            cy="2.5"
            r="2.5"
            className="transition"
            style={{ color: fill }}
            transform="translate(246 151)"
          />
        </g>
      </g>
    </Styled.SvgIcon>
  )
}

export default MenuClosed
