import { createGlobalStyle } from "styled-components"

// const HeeboFontFaces = css`
//   @font-face {
//     font-family: "Heebo";
//     src: url("/fonts/Heebo-Regular.ttf") format("truetype");
//     font-weight: normal;
//   }
//   @font-face {
//     font-family: "Heebo";
//     src: url("/fonts/Heebo-Medium.ttf") format("truetype");
//     font-weight: 500;
//   }

//   @font-face {
//     font-family: "Heebo";
//     src: url("/fonts/Heebo-Bold.ttf") format("truetype");
//     font-weight: bold;
//   }
// `

const GlobalStyle = createGlobalStyle`


  :root {
    /* font-family: "FordAntennaCond, Heebo, sans-serif"; */
    font-family: 'Raleway', sans-serif;
    height: 100%;

  }
  body{
    height: 100%;
    overflow: hidden;
  }

  #__next {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`

export default GlobalStyle
