import colors from "theme/colors"

export const clients = {
  primaryColor: colors.darkBlue,
  secondaryColor: colors.white,
  menuColor: [colors.darkBlue, colors.darkBlue],
  background: [
    {
      color: colors.goldenYellow,
      top: "-10%",
    },
    {
      color: colors.goldenYellow,
      top: "-50%",
      scale: 2,
    },
  ],
  arrowColor: [colors.darkBlue, colors.darkBlue],
  hash: "clients",
  progressBarText: "Clients",
  progressBarColors: {
    activeColor: colors.darkBlue,
    inactiveColor: colors.grayWhite,
  },
}
