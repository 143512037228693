import { heIL } from "@material-ui/core/locale"
import { createMuiTheme, ThemeOptions } from "@material-ui/core/styles"
import createBreakpoints from "@material-ui/core/styles/createBreakpoints"
import { Overrides } from "@material-ui/core/styles/overrides"
import colors from "./colors"

const breakpoints = createBreakpoints({})

const muiThemeOptions: ThemeOptions = {
  spacing: 3,
  typography: {
    // fontFamily: "FordAntennaCond, Heebo, sans-serif",
    fontFamily: "Raleway, sans-serif",
    h1: {
      fontSize: "23px",
      fontWeight: "normal",
      color: colors.darkBlue,
      [breakpoints.up("md")]: {
        fontSize: "36px",
      },
    },
    h2: {
      fontSize: "36px",
      fontWeight: "bold",
      color: colors.darkBlue,
      [breakpoints.up("md")]: {
        fontSize: "36px",
      },
    },
    h3: {
      fontSize: "28px",
      fontWeight: "bold",
      color: colors.darkRoyalBlue,
      [breakpoints.up("md")]: {
        fontSize: "36px",
      },
    },
    body1: {
      // default font:
      fontSize: "1rem",
    },
    body2: {
      fontSize: "0.875rem",
    },
  },
  palette: {
    background: {
      default: colors.white,
    },
    primary: {
      main: colors.darkBlue,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.white,
      contrastText: colors.darkBlue,
    },
    text: {
      primary: colors.darkBlue,
      secondary: colors.white,
    },
    error: {
      main: "#b50303",
    },
  },
  props: {
    MuiFormControl: {
      fullWidth: true,
    },
    MuiTextField: {
      fullWidth: true,
      variant: "outlined",
    },
    MuiInputLabel: {
      shrink: true,
    },
    MuiOutlinedInput: {
      notched: true,
    },
    MuiButton: {
      disableTouchRipple: true,
      disableFocusRipple: true,
      disableRipple: true,
    },
    MuiDialog:{
      maxWidth:"md",

    }
  },
}

const overrides: Overrides = {
  MuiBackdrop: {
    root: {
      backgroundColor: "rgba(1, 21, 46, 0.55)",
    },
  },
  MuiContainer: {
    root: {
      paddingRight: "15px",
      paddingLeft: "15px",
      [breakpoints.up("sm")]: {
        paddingRight: "15px",
        paddingLeft: "15px",
      },
    },
  },
  MuiInputLabel: {
    outlined: {
      legend: {
        fontWeight: "bold",
        fontSize: "14px",
        [breakpoints.up("md")]: {
          fontSize: "16px",
        },
      },
      "&$shrink": {
        transform: "translate(15px, -8px) scale(1)",
      },
    },
  },

  MuiDialog: {
    paperWidthSm: {
      maxWidth: "calc(100% - 30px)",
    },
    paperScrollPaper: {
      maxHeight: "calc(100% - 90px)",
      margin: "45px 15px",
      overflowY: "initial",
    },
    paper: {
      width: "100%",
      margin: "32px 6px;",
    },
    paperScrollBody: {
      overflow: "visible",
      margin: "50px 6px;",

      [breakpoints.down("sm")]: {
        width: "calc(100% - 12px)",
        maxWidth: "100% !important",
      },
    },

  },
  MuiDialogContent: {
    root: {
      borderRadius: "5px",
      boxShadow: "0 5px 99px 0 rgba(9, 42, 70, 0.6)",
      border: "solid 1px #707070",
      backgroundColor: colors.white,
    },
  },
  MuiGridListTileBar: {
    titleWrap: { flexGrow: 0 },
    title: {
      fontSize: "1.25rem",
      [breakpoints.up("md")]: {
        fontSize: "1.5rem",
      },
    },
  },
  MuiSvgIcon: {
    colorPrimary: {
      color: colors.darkBlue,
    },
  },
  MuiButton: {
    contained: {
      color: colors.darkBlue,
      backgroundColor: colors.white,
      border: `1px solid ${colors.white}`,
      outline: null,
      padding: null,
      boxShadow: null,
      "&:hover,&:focus": {
        border: null,
        boxShadow: null,
        color: colors.white,
        backgroundColor: colors.darkBlue,
      },
    },
    outlined: {
      color: colors.darkBlue,
      backgroundColor: colors.white,
      outline: null,
      padding: null,
      boxShadow: null,
      border: `2px solid ${colors.hotPink}`,
      "&:hover,&:focus": {
        color: colors.white,
        backgroundColor: colors.darkBlue,
        border: null,
        boxShadow: null,
      },
    },
    outlinedSecondary: {
      color: colors.white,
      backgroundColor: "transparent",
      border: `2px solid ${colors.white}`,
      "&:hover": {
        color: colors.white,
        backgroundColor: "transparent",
        border: null,
      },
    },
    sizeSmall: {
      fontSize: "14px",
      [breakpoints.down("sm")]: {
        padding: "5px 0",
      },
    },
    root: {
      borderRadius: 25,
      fontWeight: 600,
      fontSize: "16px",
      textTransform: "none",
      [breakpoints.down("sm")]: {
        width: "auto",
        padding: "8px 24px",
      },
      [breakpoints.up("md")]: {
        padding: "10px 60px",
        // marginTop: "20px",
        fontSize: "20px",
      },
      boxShadow: null,
      "&:hover": {
        boxShadow: null,
      },
    },
    startIcon: {
      marginLeft: "-20px",
      fontSize: "1.5em",
      marginTop: "-5px",
      marginBottom: "-5px",
    },
  },

  // MuiOutlinedInput: {
  //   root: {
  //     borderRadius: 0,
  //     "& legend": {
  //       paddingLeft: "6px",
  //       fontWeight: "bold",
  //       fontSize: "14px",
  //       [breakpoints.up("md")]: {
  //         fontSize: "16px",
  //       },
  //     },
  //     "& $notchedOutline": {
  //       borderColor: colors.silver,
  //       borderWidth: 1.5,
  //     },
  //     "&:hover $notchedOutline": {
  //       borderColor: colors.bluishGrey,
  //       borderWidth: 1.5,
  //     },
  //     "&$focused $notchedOutline": {
  //       borderColor: colors.bluishGrey,
  //       borderWidth: 1.5,
  //     },
  //     "&$error $notchedOutline": {
  //       borderColor: colors.error,
  //       borderWidth: 1.5,
  //     },
  //   },
  //   notchedOutline: {
  //     top: "0",
  //   },
  // },

  // MuiFormLabel: {
  //   root: {
  //     flexBasis: "100%",
  //     color: colors.marine,
  //     fontWeight: "bold",
  //     fontSize: "14px",
  //     [breakpoints.up("md")]: {
  //       fontSize: "16px",
  //     },
  //     "&$error": {
  //       color: colors.marine,
  //     },
  //     "&$focused": {
  //       color: colors.marine,
  //     },
  //   },
  //   asterisk: {
  //     marginLeft: "2px",
  //     verticalAlign: "top",
  //     color: colors.error,
  //     [breakpoints.up("md")]: {
  //       fontSize: "18px",
  //     },
  //   },
  // },

  // MuiFormControlLabel: {
  //   root: {
  //     alignItems: "flex-start",
  //     ".MuiFormGroup-root &": {
  //       display: "inline-block",
  //       marginRight: "0",
  //       marginLeft: "0",
  //       "& .MuiButtonBase-root": {
  //         display: "none",
  //       },
  //     },
  //   },
  // },

  // MuiInput: {
  //   input: {
  //     borderRadius: 0,
  //     height: "40px",
  //     boxSizing: "border-box",
  //   },
  //   root: {
  //     "&$disabled": {
  //       color: colors.silver,
  //     },
  //   },
  // },

  // MuiFormHelperText: {
  //   root: {
  //     marginTop: 0,
  //     "&$error": {
  //       fontSize: "12px",
  //       fontWeight: "bold",
  //       color: colors.error,
  //     },
  //   },
  // },
  // MuiAccordionDetails: {
  //   root: {
  //     padding: "3px 30px 25px 25px",
  //     [breakpoints.up("md")]: {
  //       padding: "3px 50px 50px 40px",
  //     },
  //   },
  // },
  // MuiAccordionSummary: {
  //   root: {
  //     padding: "0px",
  //     "&$expanded": {
  //       minHeight: "",
  //     },
  //   },
  //   content: {
  //     "&$expanded": {
  //       margin: "",
  //     },
  //   },
  //   expandIcon: {
  //     "&$expanded": {
  //       transform: "scaleY(-1)",
  //     },
  //   },
  // },
  // MuiInputAdornment: {
  //   root: {
  //     color: colors.bluishGrey,
  //   },
  // },
}
muiThemeOptions.overrides = overrides

const theme = {
  ...createMuiTheme(muiThemeOptions, heIL),
  colors,
}

export default theme

export type Theme = typeof theme
