import { trackPage } from "common/analytics"
import GlobalContextProvider from "common/GlobalContextProvider"
import Layout from "layout/Layout"
import { DefaultSeo } from "next-seo"
import { AppProps } from "next/app"
// import dynamic from "next/dynamic"
import { useRouter } from "next/router"
import { useEffect } from "react"
import theme from "theme/theme"
import ThemeProviders from "theme/ThemeProviders"

const ORIGIN = process.env.ORIGIN
if (!ORIGIN) {
  throw new Error("Please provide the ORIGIN environment variable.")
}

const MyApp = ({ Component, pageProps }: AppProps) => {
  useEffect(() => {
    // Remove the server-side injected CSS
    const jssStyles = document.querySelector("#jss-server-side")
    jssStyles?.parentElement?.removeChild(jssStyles)

    trackPage()
  }, [])

  const { asPath } = useRouter()
  const url = (ORIGIN + asPath).split(/[?#]/)[0] // remove query string & hash
  return (
    <>
      <DefaultSeo
        // titleTemplate="%s - mint"
        canonical={url}
        openGraph={{
          site_name: "Mint",
          locale: "he_IL",
          type: "website",
          url,
        }}
        additionalMetaTags={[
          { name: "theme-color", content: theme.colors.darkBlue },
        ]}
      />
      <ThemeProviders>
        <GlobalContextProvider>
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </GlobalContextProvider>
      </ThemeProviders>
    </>
  )
}

export default MyApp
