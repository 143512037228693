import colors from "theme/colors"

export const eldan = {
  primaryColor: colors.white,
  secondaryColor: colors.eldanYellow,
  menuColor: [colors.white, colors.eldanDarkBlue],
  background: [
    {
      color: colors.eldanBlue,
      top: "-45%",
      scale: 0.6,
    },
    {
      color: colors.eldanBlue,
      top: "-10%",
      right: "50%",
      left: "auto",
      scale: 1,
    },
  ],
  arrowColor: [colors.eldanBlue, colors.darkBlue],
  buttonColors: {
    color: [colors.eldanYellow, colors.eldanYellow],
    textColor: [colors.eldanDarkBlue, colors.eldanDarkBlue],
  },
  bouncingIcons: [
    {
      image: "./eldan/eldan-left.svg",
      deg: 45,
      right: ["", ""],
      left: ["0px", "55%"],
      bottom: "10%",
      width: ["95px", "158.9px"],
      height: ["82.2px", "137.4px"],
    },
    {
      image: "./eldan/eldan-right.svg",
      deg: 45,
      right: ["", "91px"],
      left: ["", ""],
      top: "20%",
      width: ["0px", "119.2px"],
      height: ["0px", "108.1px"],
    },
    {
      image: "./eldan/eldan-right-small.svg",
      deg: 135,
      right: ["20px", "18px"],
      left: [null, null],
      bottom: "40%",
      width: ["", "73.2px"],
      height: ["", "75.7px"],
    },
  ],
  product: {
    name: "Eldan",
    description:
      "All of Eldan's services in one platform that offers an advanced user experience to purchase and lease your next car.",
    logo: "/eldan/eldan-logo",
    link: "https://eldan.co.il",
    image: "/eldan/eldan-phone",
    imageDeg: 15,
    services: ["UI/UX", "Web Development", "CMS"],
    servicesColor: {
      color: colors.eldanLightBlue,
      textColor: colors.eldanDarkBlue,
    },
    platform: "web",
    textColor: colors.white,
  },
  hash: "eldan",
  progressBarText: "Projects",
  progressBarColors: {
    activeColor: colors.eldanYellow,
    inactiveColor: colors.white,
  },
}
