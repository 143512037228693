import colors from "theme/colors"

export const buyMe = {
  primaryColor: colors.darkBlue,
  secondaryColor: colors.buymePink,
  menuColor: [colors.buymePink, colors.buymePink],
  background: [
    {
      color: colors.buymeYellow,
      top: "50%",
      scale: 0.6,
    },
    {
      color: colors.buymeYellow,
      top: "-10%",
      left: "50%",
      // left: "auto",
      scale: 1,
    },
  ],
  arrowColor: [colors.darkBlue, colors.darkBlue],
  buttonColors: {
    color: [colors.buymePink, colors.buymePink],
    textColor: [colors.white, colors.white],
  },
  bouncingIcons: [
    {
      image: "./buyme/buyme-left.svg",
      deg: 45,
      right: ["", ""],
      left: ["0px", "55%"],
      bottom: "10%",
      width: ["95px", "158.9px"],
      height: ["82.2px", "137.4px"],
    },
    {
      image: "./buyme/buyme-left.svg",
      deg: 60,
      right: ["", "80px"],
      left: ["", ""],
      top: "20%",
      width: ["0px", "119.2px"],
      height: ["0px", "108.1px"],
    },
    {
      image: "./buyme/buyme-right.svg",
      deg: 135,
      right: ["20px", "18px"],
      left: [null, null],
      bottom: "40%",
      width: ["", "73.2px"],
      height: ["", "75.7px"],
    },
  ],
  product: {
    name: "BUYME",
    description:
      "Digital gift card platform for consumers and employees, enabling both gift card purchase and redeem in an intuitive & user friendly experience.",
    logo: "/buyme/buyme-logo",
    link: "https://buyme.onelink.me/lRiV/Installsmsfeb",
    image: "/buyme/buyme-phone",
    imageDeg: 0,
    services: ["UI/UX", "App Development", "Design thinking workshop"],
    servicesColor: {
      color: colors.buymeLightPink,
      textColor: colors.buymePink,
    },
    platform: "app",
  },
  hash: "buyme",
  progressBarText: "Projects",
  progressBarColors: {
    activeColor: colors.darkBlue,
    inactiveColor: colors.grayWhite,
  },
}
