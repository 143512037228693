import { FC, useRef } from "react"
import styled from "styled-components"
import Header from "./Header"

const Styled = {
  Main: styled.main`
    flex-grow: 1;
    height: 100%;
    ${(p) => p.theme.breakpoints.up("md")} {
      display: flex;
      flex-direction: column;
    }

    :focus {
      outline: 0;
    }
  `,
}

const Layout: FC<any> = ({ children }) => {
  const mainRef = useRef<HTMLDivElement>(null)
  return (
    <>
      <Header />
      <Styled.Main id="mainContent" tabIndex={-1} ref={mainRef}>
        {children}
      </Styled.Main>

      {/* <Footer /> */}
    </>
  )
}

export default Layout
