import colors from "theme/colors"

export const amot = {
  primaryColor: colors.darkBlue,
  secondaryColor: colors.amotRed,
  menuColor: [colors.amotRed, colors.amotRed],
  background: [
    {
      color: colors.amotLightPink,
      top: "50%",
      scale: 0.6,
    },
    {
      color: colors.amotLightPink,
      top: "-10%",
      right: "50%",
      left: "auto",
      scale: 1,
    },
  ],
  arrowColor: [colors.darkBlue, colors.darkBlue],
  buttonColors: {
    color: [colors.amotRed, colors.amotRed],
    textColor: [colors.white, colors.white],
  },
  bouncingIcons: [
    {
      image: "./amot/amot-left.svg",
      deg: -45,
      right: ["", ""],
      left: ["30px", "55%"],
      bottom: "20%",
      width: ["48px", "78px"],
      height: ["49px", "80px"],
    },
    {
      image: "./amot/amot-left.svg",
      deg: 135,
      right: ["", "91px"],
      left: ["", ""],
      top: "20%",
      width: ["0px", "119.2px"],
      height: ["0px", "108.1px"],
    },
    {
      image: "./amot/amot-right-small.svg",
      deg: 45,
      right: ["-20px", "-10px"],
      left: [null, null],
      bottom: "40%",
      width: ["81px", "73.2px"],
      height: ["81px", "75.7px"],
    },
  ],
  product: {
    name: "Amot",
    description:
      "Taking smart services in a workspace to a whole new level with digital access to entrance, parking, gym and all other services available in a click of a button.",
    logo: "/amot/amot-logo",
    link: "https://amot.page.link/install",
    image: "/amot/amot-phone",
    imageDeg: 0,
    services: ["UI/UX", "App Development", "CMS"],
    servicesColor: {
      color: colors.black,
      textColor: colors.white,
    },
    platform: "app",
  },
  hash: "amot",
  progressBarText: "Projects",
  progressBarColors: {
    activeColor: colors.darkBlue,
    inactiveColor: colors.white,
  },
}
