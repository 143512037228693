import colors from "theme/colors"

export const home = {
  primaryColor: colors.darkBlue,
  secondaryColor: colors.hotPink,
  menuColor: [colors.darkBlue, colors.darkBlue],
  background: [
    {
      color: colors.white,
      top: "100vh",
    },
    {
      color: colors.white,
      top: "100vh",
    },
  ],
  arrowColor: [colors.darkBlue, colors.darkBlue],
  hash: "home",
  progressBarText: "Home",
  progressBarColors: {
    activeColor: colors.darkBlue,
    inactiveColor: colors.grayWhite,
  },
}
