import { SvgIcon, SvgIconProps } from "@material-ui/core"
import { GlobalContext } from "common/GlobalContext"
import { useIsDesktop } from "common/MediaQueries"
import { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import colors from "theme/colors"
import { transition } from "theme/shared"

const Styled = {
  SvgIcon: styled(SvgIcon)`
    .transition {
      fill: currentColor;
      ${transition};
    }
  `,
}

const MintLogo = (props: SvgIconProps) => {
  const [fill, setFill] = useState("")
  const [bracketsFill, setBracketsFill] = useState("")
  const { activeSection, openMenu } = useContext(GlobalContext)
  const isDesktop = useIsDesktop()

  useEffect(() => {
    setFill(
      // openMenu
      //   ? colors.white
      //   : isDesktop && activeSection.hash == "contact"
      //   ? colors.white
      //   : activeSection.primaryColor
      openMenu ? colors.white : activeSection.primaryColor
    )
    setBracketsFill(
      // openMenu
      //   ? colors.hotPink
      //   : isDesktop && activeSection.hash == "contact"
      //   ? colors.darkBlue
      //   : activeSection.secondaryColor
      openMenu ? colors.hotPink : activeSection.secondaryColor
    )
  }, [openMenu, activeSection])

  if (isDesktop) {
    return (
      <Styled.SvgIcon {...props} viewBox="0 0 231.2 67.3">
        <g id="Logo" transform="translate(-96.617 -250)">
          <text
            id="Marketing_Innovation_Technology"
            transform="translate(207.617 315.34)"
            fontSize="10px"
            fontFamily="Raleway-Medium, Raleway"
            className="transition"
            style={{ color: fill }}
            fontWeight="500"
            letterSpacing="0.2em"
          >
            <tspan x="-110.17" y="0">
              Marketing
            </tspan>
            <tspan y="0" style={{ color: bracketsFill }}>
              _
            </tspan>
            <tspan y="0">Innovation</tspan>
            <tspan y="0" style={{ color: bracketsFill }}>
              _
            </tspan>
            <tspan y="0">Technology</tspan>
          </text>
          <g id="Group_85" data-name="Group 85" transform="translate(99.2 250)">
            <path
              id="Right_angle_bracket"
              data-name="Right_angle_bracket"
              style={{ color: bracketsFill, stroke: "currentColor" }}
              d="M.7 35.086a2.557 2.557 0 0 1 0-3.617l13.279-13.275L.664 4.879a2.832 2.832 0 0 1 4-4l14.672 14.662a2.829 2.829 0 0 1 .709 2.821 2.549 2.549 0 0 1-.749 1.749L4.322 35.086a2.558 2.558 0 0 1-3.618 0z"
              transform="translate(209.417 11.34)"
            />
            <path
              id="Left_angle_bracket"
              style={{ color: bracketsFill, stroke: "currentColor" }}
              data-name="Union 20"
              d="M.7 35.086a2.557 2.557 0 0 1 0-3.617l13.279-13.275L.664 4.879a2.832 2.832 0 0 1 4-4l14.672 14.662a2.829 2.829 0 0 1 .709 2.821 2.549 2.549 0 0 1-.749 1.749L4.322 35.086a2.558 2.558 0 0 1-3.618 0z"
              transform="rotate(180 10.209 23.67)"
            />
            <g id="Group_84" data-name="Group 84" transform="translate(37.247)">
              <path
                id="Union_21"
                data-name="Union 21"
                style={{ color: fill, stroke: "currentColor" }}
                d="M26.543 31.491v-13.8a9 9 0 0 0-18 0v13.575a4.274 4.274 0 1 1-8.547 0V17.47A17.47 17.47 0 0 1 29.823 5.117 17.354 17.354 0 0 1 34.94 17.47v14.021a4.2 4.2 0 1 1-8.4 0z"
                transform="translate(89.225 11.247)"
              />
              <path
                id="M_letter"
                data-name="Union 24"
                style={{ color: fill, stroke: "currentColor" }}
                d="M53.086 31.491v-13.8a9 9 0 0 0-18 0v13.575a4.257 4.257 0 0 1-.924 2.654 4.2 4.2 0 0 1-7.624-2.429v-13.8a9 9 0 0 0-18 0v13.575a4.274 4.274 0 1 1-8.547 0V17.47A17.47 17.47 0 0 1 29.823 5.117q.48.48.918.991.438-.51.919-.991A17.469 17.469 0 0 1 61.483 17.47v14.021a4.2 4.2 0 1 1-8.4 0z"
                transform="translate(0 11.247)"
              />
              <g id="i" transform="translate(70.93)">
                <rect
                  id="I_letter"
                  data-name="Rectangle 19"
                  style={{ color: fill }}
                  width="8.847"
                  height="34.94"
                  rx="4.424"
                  transform="translate(0 11.997)"
                />
                <circle
                  id="Ellipse_9"
                  data-name="Ellipse 9"
                  cx="4.424"
                  cy="4.424"
                  r="4.424"
                  style={{ color: bracketsFill }}
                />
              </g>
              <path
                id="Path_165"
                data-name="Path 165"
                style={{ color: fill }}
                d="M-318.755 594.307a4.435 4.435 0 0 1 1.065 3.066 3.836 3.836 0 0 1-1.405 3.066 5.394 5.394 0 0 1-3.619 1.192h-1.533a14.018 14.018 0 0 1-7.622-2.172 15.121 15.121 0 0 1-5.408-5.919 17.909 17.909 0 0 1-1.959-8.431v-11.763h-6.057a4.1 4.1 0 0 1-2.9-1.022 3.331 3.331 0 0 1-1.107-2.555 3.531 3.531 0 0 1 1.107-2.725 4.1 4.1 0 0 1 2.9-1.022h6.057v-6.558a4.147 4.147 0 0 1 1.192-3.065 4.151 4.151 0 0 1 3.066-1.193 4.155 4.155 0 0 1 3.066 1.193 4.152 4.152 0 0 1 1.192 3.065v6.558h10.092a4.1 4.1 0 0 1 2.9 1.022 3.535 3.535 0 0 1 1.107 2.725 3.336 3.336 0 0 1-1.107 2.555 4.1 4.1 0 0 1-2.9 1.022h-10.092v11.763a8.738 8.738 0 0 0 1.874 5.706 5.722 5.722 0 0 0 4.6 2.3h2.9a3.356 3.356 0 0 1 2.591 1.192z"
                transform="translate(472.863 -554.607)"
              />
            </g>
          </g>
        </g>
      </Styled.SvgIcon>
    )
  }

  return (
    <Styled.SvgIcon {...props} viewBox="0 0 119.115 38">
      <g id="Group_119" transform="translate(-771.946 -89.423)">
        <text
          id="Marketing_Innovation_Technology"
          className="transition"
          style={{ color: fill }}
          fontFamily="Raleway-Medium, Raleway"
          fontSize="5px"
          fontWeight="500"
          letterSpacing="0.2em"
          transform="translate(777.003 125.423)"
        >
          <tspan x="0" y="0">
            Marketing
          </tspan>
          <tspan y="0" className="transition" style={{ color: bracketsFill }}>
            _
          </tspan>
          <tspan y="0">Innovation</tspan>
          <tspan y="0" className="transition" style={{ color: bracketsFill }}>
            _
          </tspan>
          <tspan y="0">Technology</tspan>
        </text>
        <g id="Group_75" transform="translate(773.946 90)">
          <g id="Group_55">
            <g id="Group_47" transform="translate(18.01)">
              <g id="Group_25" transform="translate(0 6.159)">
                <g id="Group_24" transform="translate(0 1.073)">
                  <path
                    id="Path_88"
                    d="M2101.467-1250.816v-5.517a7.707 7.707 0 0 0-7.707-7.707 7.707 7.707 0 0 0-7.707 7.707v5.563a.922.922 0 0 0-.021.192v1.408a.911.911 0 0 0 .911.911.911.911 0 0 0 .91-.911v-1.408a.912.912 0 0 0-.036-.252v-5.412a5.943 5.943 0 0 1 5.943-5.943 5.943 5.943 0 0 1 5.943 5.943v5.458a.914.914 0 0 0-.024.205v1.408a.91.91 0 0 0 .91.911.91.91 0 0 0 .91-.911v-1.408a.915.915 0 0 0-.032-.237z"
                    className="transition"
                    style={{ color: fill, stroke: "currentColor" }}
                    strokeWidth="3px"
                    transform="translate(-2086.032 1264.04)"
                  />
                  <path
                    id="Path_89"
                    d="M2363.467-1250.816v-5.517a7.707 7.707 0 0 0-7.707-7.707 7.707 7.707 0 0 0-7.707 7.707v5.563a.905.905 0 0 0-.021.192v1.408a.911.911 0 0 0 .911.911.911.911 0 0 0 .91-.911v-1.408a.912.912 0 0 0-.036-.252v-5.412a5.943 5.943 0 0 1 5.943-5.943 5.943 5.943 0 0 1 5.943 5.943v5.458a.914.914 0 0 0-.024.205v1.408a.91.91 0 0 0 .91.911.91.91 0 0 0 .91-.911v-1.408a.9.9 0 0 0-.032-.237z"
                    className="transition"
                    style={{ color: fill, stroke: "currentColor" }}
                    strokeWidth="3px"
                    transform="translate(-2334.388 1264.04)"
                  />
                </g>
                <rect
                  id="Rectangle_19"
                  width="5"
                  height="18"
                  className="transition"
                  style={{ color: fill }}
                  rx="2.5"
                  transform="translate(34.048 .263)"
                />
                <path
                  id="Path_164"
                  d="M2363.467-1250.816v-5.517a7.707 7.707 0 0 0-7.707-7.707 7.707 7.707 0 0 0-7.707 7.707v5.563a.905.905 0 0 0-.021.192v1.408a.911.911 0 0 0 .911.911.911.911 0 0 0 .91-.911v-1.408a.912.912 0 0 0-.036-.252v-5.412a5.943 5.943 0 0 1 5.943-5.943 5.943 5.943 0 0 1 5.943 5.943v5.458a.914.914 0 0 0-.024.205v1.408a.91.91 0 0 0 .91.911.91.91 0 0 0 .91-.911v-1.408a.9.9 0 0 0-.032-.237z"
                  className="transition"
                  style={{ color: fill, stroke: "currentColor" }}
                  strokeWidth="3px"
                  transform="translate(-2303.567 1265.113)"
                />
              </g>
              <circle
                id="Ellipse_9"
                cx="2.5"
                cy="2.5"
                r="2.5"
                className="transition"
                style={{ color: bracketsFill }}
                transform="translate(34.048 -.577)"
              />
            </g>
            <path
              id="Union_17"
              className="transition"
              style={{ color: bracketsFill, stroke: "currentColor" }}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="4px"
              d="M0 8.541l8.541 8.541zM8.541 0L0 8.541z"
              transform="translate(0 7.317)"
            />
            <path
              id="Union_18"
              className="transition"
              style={{ color: bracketsFill, stroke: "currentColor" }}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="4px"
              d="M0 8.541l8.541 8.541zM8.541 0L0 8.541z"
              transform="rotate(180 57.557 12.2)"
            />
          </g>
          <path
            id="Path_165"
            d="M-333.4 575.563a2.309 2.309 0 0 1 .554 1.6 2 2 0 0 1-.732 1.6 2.808 2.808 0 0 1-1.884.621h-.8a7.3 7.3 0 0 1-3.968-1.131 7.873 7.873 0 0 1-2.815-3.082 9.324 9.324 0 0 1-1.02-4.389v-6.124h-3.154a2.134 2.134 0 0 1-1.507-.532 1.735 1.735 0 0 1-.576-1.33 1.838 1.838 0 0 1 .576-1.419 2.133 2.133 0 0 1 1.507-.532h3.154v-3.414a2.159 2.159 0 0 1 .621-1.6 2.161 2.161 0 0 1 1.6-.621 2.163 2.163 0 0 1 1.6.621 2.162 2.162 0 0 1 .621 1.6v3.414h5.254a2.135 2.135 0 0 1 1.508.532 1.841 1.841 0 0 1 .576 1.419 1.737 1.737 0 0 1-.576 1.33 2.135 2.135 0 0 1-1.508.532h-5.254v6.124a4.549 4.549 0 0 0 .975 2.971 2.979 2.979 0 0 0 2.394 1.2h1.507a1.747 1.747 0 0 1 1.347.61z"
            className="transition"
            style={{ color: fill }}
            transform="translate(429.691 -555.131)"
          />
        </g>
      </g>
    </Styled.SvgIcon>
  )
}

export default MintLogo
