import colors from "theme/colors"

export const mazda = {
  primaryColor: colors.white,
  secondaryColor: colors.black,
  menuColor: [colors.white, colors.black],
  background: [
    {
      color: colors.mulberry,
      top: "-45%",
      scale: 0.6,
    },
    {
      color: colors.mulberry,
      top: "-10%",
      right: "50%",
      left: "auto",
      scale: 1,
    },
  ],
  arrowColor: [colors.black, colors.darkBlue],
  buttonColors: {
    color: [colors.black, colors.white],
    textColor: [colors.white, colors.black],
  },
  bouncingIcons: [
    {
      image: "./mazda/mazda-left.svg",
      deg: 60,
      left: ["0", "45%"],
      right: ["", ""],
      bottom: "13%",
      width: ["", "211.8px"],
      height: ["", "220.2px"],
    },
    {
      image: "./mazda/mazda-right-top.svg",
      deg: -60,
      right: ["0", "85px"],
      left: ["", ""],
      top: "30%",
      width: ["0px", "88.2px"],
      height: ["0px", "85px"],
    },
    {
      image: "./mazda/mazda-right.svg",
      deg: -60,
      right: ["0", "0"],
      left: ["", ""],
      bottom: "35%",
      width: ["", "113.6px"],
      height: ["", "114.5px"],
    },
  ],
  product: {
    name: "Mazda Online",
    description:
      "Ecommerce platform offering the user to build and purchase a car configuring its grade, exterior, interior and accessories",
    logo: "/mazda/mazda_logo",
    link: "https://shop.mazda.co.il",
    image: "/mazda/Mazda_iphone_black_3D",
    imageDeg: 0,
    services: ["UI/UX", "Web Development", "Ecommerce"],
    servicesColor: {
      color: colors.grayWhite,
      textColor: colors.mulberry,
    },
    textColor: colors.white,
    platform: "web",
  },
  hash: "mazda",
  progressBarText: "Projects",
  progressBarColors: {
    activeColor: colors.white,
    inactiveColor: colors.lightMauve,
  },
}
