import { Hidden } from "@material-ui/core"
import type { FC } from "react"
import styled, { css } from "styled-components"

const Mobile: FC = ({ children }) => <Hidden mdUp>{children}</Hidden>
const Desktop: FC = ({ children }) => <Hidden smDown>{children}</Hidden>

const Shared = {
  Mobile,
  Desktop,
}

export default Shared

export const transitionTime = 800
export const menuTransitionTime = 400
export const finalBounceEase = "cubic-bezier(.19,.88,.53,1.12)"

export const transition = css`
  transition: all ${transitionTime}ms ${finalBounceEase};
`

export const bouncingWrapper = styled.div`
  position: absolute;
`
type OffscreenProps = {
  xPos?: string | number
  yPos?: string | number
  important?: boolean
}
export const offScreen = ({
  xPos = 0,
  yPos = 0,
  important = false,
}: OffscreenProps) => css`
  transform: translate3d(${xPos}, ${yPos}, 0) ${important && "!important"};
  opacity: 0 ${important && "!important"};
  visibility: hidden ${important && "!important"};
`
export const SectionContainer = styled.div<{
  active: boolean
  openMenu: boolean
}>`
  position: absolute;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 35px;
  padding-bottom: 70px;
  text-align: center;
  z-index: 2;
  > * {
    ${transition}
    ${(p) =>
      p.active &&
      `
        transform: translate3d(0,0,0) !important;
        visibility:visible !important;
        opacity:1 !important;
    `}
    ${(p) =>
      p.openMenu && offScreen({ yPos: "calc(100% + 20vh)", important: true })}
  }
`
