import { Hidden, useMediaQuery } from "@material-ui/core"
import type { FC } from "react"
import { Theme } from "theme/theme"

export const useIsDesktop = () =>
  useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"))

export const Mobile: FC = ({ children }) => <Hidden mdUp>{children}</Hidden>

export const Desktop: FC = ({ children }) => <Hidden smDown>{children}</Hidden>

export const IEMediaQuery =
  "@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)"

export const EdgeMediaQuery = "@supports (-ms-ime-align:auto)"
