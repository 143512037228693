import { AppBar, IconButton, Toolbar } from "@material-ui/core"
import { GlobalContext } from "common/GlobalContext"
import MenuClosed from "common/icons/MenuClosed"
import MintLogo from "common/icons/MintLogo"
import NextLink from "common/NextLink"
import { useContext } from "react"
import styled from "styled-components"
import Menu from "./Menu"

const Styled = {
  AppBar: styled(AppBar)`
    box-shadow: none;
  `,
  Toolbar: styled(Toolbar)`
    padding: 30px;
    padding-top: 15px;
    justify-content: space-between;
    ${(p) => p.theme.breakpoints.up("md")} {
      padding: 40px 100px 30px 100px;

      @media (max-height: 650px) {
        padding: 30px 65px;
      }
    }
  `,
  LogoIconButton: styled(IconButton)`
    padding: 0;
  `,
  MintLogo: styled(MintLogo)`
    width: 115px;
    height: 37px;

    ${(p) => p.theme.breakpoints.up("md")} {
      width: 200px;
      height: 60px;
    }

    ${(p) => p.theme.breakpoints.up("lg")} {
      width: 231px;
      height: 67px;
    }
  `,
  MenuClosed: styled(MenuClosed)`
    width: 30px;
    height: 28px;
    ${(p) => p.theme.breakpoints.up("md")} {
      width: 44px;
      height: 44px;
    }
  `,
}

const Header = () => {
  const { openMenu, setOpenMenu } = useContext(GlobalContext)
  return (
    <>
      <Styled.AppBar position="fixed" color="transparent">
        <Styled.Toolbar>
          <NextLink href="/#home" passHref>
            <Styled.LogoIconButton>
              <Styled.MintLogo />
            </Styled.LogoIconButton>
          </NextLink>
          <IconButton
            edge="start"
            aria-label="menu"
            onClick={() => setOpenMenu(!openMenu)}
          >
            <Styled.MenuClosed />
          </IconButton>
        </Styled.Toolbar>
      </Styled.AppBar>
      <Menu />
    </>
  )
}

export default Header
