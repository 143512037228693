import { ColoredBackgroundCircleProps } from "common/ColoredBackgroundCircle"
import {
  amot,
  buyMe,
  cellcom,
  centralPark,
  clients,
  cocaCola,
  contact,
  eldan,
  employees,
  geely,
  histadrut,
  home,
  mazda,
  mcdonalds,
  services,
  strauss,
  superPharm,
  toyota,
  yellow,
} from "./sectionData"

export type Product = {
  name: string
  logo: string
  description: string
  services: string[]
  servicesColor: ServicesColors
  image: string
  imageDeg: number
  link: string
  textColor?: string
  platform: "app" | "web"
}

// in both arrays, index 0 is mobile and 1 is desktop
export type ButtonColors = {
  color?: string[]
  textColor?: string[]
}

export type ServicesColors = {
  color?: string
  textColor?: string
}

// for all arrays, index 0 represents mobile and 1 is desktop
export type BouncingIcon = {
  image: string
  deg: number
  top?: string
  left?: string[]
  bottom?: string
  right?: string[]
  width?: string[]
  height?: string[]
}

export type ProgressBarColors = {
  activeColor: string
  inactiveColor: string
}

// for all arrays (except BouncingIcon), index 0 represents mobile and index 1 is desktop
export type SectionType = {
  primaryColor: string
  secondaryColor: string
  menuColor?: string[]
  arrowColor?: string[]
  buttonColors?: ButtonColors
  background: ColoredBackgroundCircleProps[]
  product?: Product
  bouncingIcons?: BouncingIcon[]
  hash: string
  progressBarText: string
  progressBarColors: ProgressBarColors
}

export const sections: SectionType[] = [
  home,
  services,
  clients,
  cocaCola,
  strauss,
  yellow,
  superPharm,
  cellcom,
  mazda,
  histadrut,
  buyMe,
  centralPark,
  toyota,
  geely,
  eldan,
  amot,
  mcdonalds,
  employees,
  contact,
]

// for all arrays (except BouncingIcon), index 0 represents mobile and index 1 is desktop
export type Section = {
  primaryColor: string
  secondaryColor: string
  menuColor?: string[]
  arrowColor?: string[]
  buttonColors?: ButtonColors
  background: ColoredBackgroundCircleProps[]
  product?: Product
  bouncingIcons?: BouncingIcon[]
  hash: string
  progressBarText: string
  progressBarColors: ProgressBarColors
}
