import colors from "theme/colors"

export const contact = {
  primaryColor: colors.darkBlue,
  secondaryColor: colors.hotPink,
  menuColor: [colors.darkBlue, colors.darkBlue],
  background: [
    {
      color: colors.brightSkyBlue,
      top: "100%",
    },
    {
      color: colors.brightSkyBlue,
      top: "-20%",
      right: "60%",
      left: "auto",
      scale: 1,
    },
  ],
  arrowColor: [colors.darkBlue, colors.darkBlue],
  hash: "contact",
  progressBarText: "Contact",
  progressBarColors: {
    activeColor: colors.darkBlue,
    inactiveColor: colors.grayWhite,
  },
}
