import Link from "next/link"
import { useRouter } from "next/router"
import React, { memo } from "react"
import styled from "styled-components"
import type { UrlObject } from "url"

const StyledA = styled.a`
  text-decoration: none;
`

const keepOnlyUtmParams = (search: string): string => {
  if (!search) return ""
  const params = search.split("&")
  return params.filter((param) => param.includes("utm")).join("&")
}

type NextLinkProps = {
  href: string
  passHref?: boolean
  openInNewTab?: boolean
  children: React.ReactNode
  notEncoding?: boolean
  className?: string
}

const NextLink: React.FunctionComponent<NextLinkProps> = ({
  href,
  passHref,
  children,
  openInNewTab = false,
  notEncoding = false,
  className,
}) => {
  const { asPath } = useRouter()
  const idx = asPath.indexOf("?")
  const search = idx >= 0 ? asPath.slice(idx).split("#")[0] : undefined
  const urlWithHash = href.split("#")
  const link: UrlObject = {
    pathname: urlWithHash[0],
    search: keepOnlyUtmParams(search),
    hash: urlWithHash[1] ?? null,
  }

  return (
    <Link href={notEncoding ? href : link} passHref>
      {openInNewTab ? (
        <StyledA target="_blank" rel="noreferrer" className={className}>
          {children}
        </StyledA>
      ) : passHref ? (
        children
      ) : (
        <StyledA>{children}</StyledA>
      )}
    </Link>
  )
}

export default memo(NextLink)
