export { amot } from "./amot"
export { buyMe } from "./buyMe"
export { cellcom } from "./cellcom"
export { centralPark } from "./centralPark"
export { clients } from "./clients"
export { cocaCola } from "./cocaCola"
export { contact } from "./contact"
export { eldan } from "./eldan"
export { employees } from "./employees"
export { geely } from "./geely"
export { histadrut } from "./histadrut"
export { home } from "./home"
export { mazda } from "./mazda"
export { mcdonalds } from "./mcdonalds"
export { services } from "./services"
export { strauss } from "./strauss"
export { superPharm } from "./superPharm"
export { toyota } from "./toyota"
export { yellow } from "./yellow"
