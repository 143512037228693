import colors from "theme/colors"

export const superPharm = {
  primaryColor: colors.white,
  secondaryColor: colors.cherry,
  menuColor: [colors.white, colors.cobaltBlue],
  background: [
    {
      color: colors.cobaltBlue,
      top: "-45%",
      scale: 0.6,
    },
    {
      color: colors.cobaltBlue,
      top: "-10%",
      right: "50%",
      left: "auto",
      scale: 1,
    },
  ],
  arrowColor: [colors.cobaltBlue, colors.darkBlue],
  buttonColors: {
    color: [colors.brightRed, colors.brightRed],
    textColor: [colors.white, colors.white],
  },
  bouncingIcons: [
    {
      image: "./sp/sp-right.svg",
      deg: 60,
      left: ["-20px", "50%"],
      right: ["", ""],
      bottom: "10%",
      width: ["86px", "198.5px"],
      height: ["86px", "198.5px"],
    },
    {
      image: "./sp/sp-right.svg",
      deg: -45,
      right: ["23px", "28px"],
      left: ["", ""],
      bottom: "60%",
      width: ["86px", "122.9px"],
      height: ["86px", "122.9px"],
    },
    {
      image: "./sp/sp-right.svg",
      deg: 240,
      right: ["23px", "28px"],
      left: ["", ""],
      bottom: "40%",
      width: ["27px", "51.8px"],
      height: ["27px", "51.8px"],
    },
  ],
  product: {
    name: "Super-Pharm Online",
    description:
      "Ecommerce & Marketplace platform offering a personalized shopping experience by both Super-Pharm and 3rd party vendors",
    logo: "/sp/sp-online",
    link: "https://shop.super-pharm.co.il",
    image: "/sp/superpharm_iphone_red_3D",
    imageDeg: 0,
    services: ["UI/UX", "Web Development", "Functional Detail Design"],
    servicesColor: {
      color: colors.iceBlue,
      textColor: colors.darkBlue,
    },
    textColor: colors.white,
    platform: "web",
  },
  hash: "super-pharm",
  progressBarText: "Projects",
  progressBarColors: {
    activeColor: colors.white,
    inactiveColor: colors.lightPeriwinkle,
  },
}
