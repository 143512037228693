import colors from "theme/colors"

export const yellow = {
  primaryColor: colors.darkRoyalBlue,
  secondaryColor: colors.dandelionYellow,
  menuColor: [colors.darkRoyalBlue, colors.darkRoyalBlue],
  background: [
    {
      color: colors.dandelionYellow,
      top: "50%",
      scale: 0.6,
    },
    {
      color: colors.dandelionYellow,
      top: "-10%",
      left: "52%",
      scale: 1,
    },
  ],
  arrowColor: [colors.black, colors.darkBlue],
  buttonColors: {
    color: [colors.cherryTwo, colors.dandelionYellow],
    textColor: [colors.white, colors.darkRoyalBlue],
  },
  bouncingIcons: [
    {
      image: "./yellow/yellow-left.svg",
      deg: 50,
      left: ["-20px", "40%"],
      right: ["", ""],
      bottom: "50%",
      width: ["", "231.3px"],
      height: ["", "234px"],
    },
    {
      image: "./yellow/yellow-top-right.svg",
      deg: 150,
      left: ["", ""],
      right: ["", "5%"],
      top: "30%",
      width: ["0px", "91.5px"],
      height: ["0px", "93.9px"],
    },
    {
      image: "./yellow/yellow-right.svg",
      deg: 155,
      right: ["17px", "-10px"],
      left: ["", ""],
      bottom: "29%",
      width: ["", "109.4px"],
      height: ["", "164.5px"],
    },
  ],
  product: {
    name: "Yellow",
    description:
      "Digital wallet based product allowing users to pay for gas and in store products while earning cashback and a variety of benefits",
    logo: "/yellow/yellow_logo",
    link: "https://yellow.onelink.me/HpLh/a4aed4b0",
    image: "/yellow/Paz_iphone_white_3D",
    imageDeg: 30,
    services: ["Product Management", "App Development", "B2C"],
    servicesColor: {
      color: colors.pale,
      textColor: colors.cherryTwo,
    },
    platform: "app",
  },
  hash: "yellow",
  progressBarText: "Projects",
  progressBarColors: {
    activeColor: colors.darkBlue,
    inactiveColor: colors.grayWhite,
  },
}
